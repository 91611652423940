<template>
  <b-container class="bv-example-row">
    <b-row class="text-center row">
      <b-col md="4">
        <model-select
          class="form-control"
          :options="options"
          v-model="searchValue"
          placeholder="select cordinator"
          :isError="true"
        ></model-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  name: 'searchable-dropdown',
  components: {
    ModelSelect,
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    selectedOption: {
      default: null,
    },
  },
  data() {
    return {
      searchValue: null,
    }
  },
  watch: {
    searchValue: function (value, oldvalue) {
      let select = this.$props.options.filter((x) => x.value === value)[0]
      this.$emit('input', select)
    },
  },
  methods: {
    getvalue(item) {
      this.$emit('input', select)
    },
  },
  created() {
    if (this.$props.selectedOption !== null) {
      this.searchValue = this.selectedOption
    }
  },
}
</script>
<style scoped>
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  /* box-sizing: border-box; */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}
#drop-input {
  width: 340px;
}

#myInput:focus {
  outline: 3px solid #ddd;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
  width: 340px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 300px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}
</style>
